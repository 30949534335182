.register{
	height: calc(100vh - 70px);
	background-size: cover !important;
	overflow-y: auto;
}
.register-scroll{
    overflow-y: auto;
    height: calc(100vh - 70px);
    position: absolute;
    bottom: 70px;
    left: 0;
    width: 100%;
}
.register .register-box{
	background: #fff;
	margin:20px;
	padding: 20px;
	box-shadow: -4px 3px 6px #00000029;
	border-radius: 4px;
	margin-top: calc(100vh - 22px - 100%);
}
.register .background-caption{
	position:absolute;
	top:-50px;
	left: 0;
	color: #fff;
	font-size:12px;
	text-shadow: 0px 0px 6px #000000A8;
}
.register h3{
	color: #483A3A;
	font-weight: bold;
	font-size:20px;
}
.register .subtitle{
	color: #483A3A;
	font-size:14px;
	margin:0;
}
.register .subtitle span{
	color: #0AA09F;
	font-weight: bold;
}
.register .subtitle .btn-link{
	color: #0AA09F;
	font-weight:bold;
	padding:0;
}
.register .buttons-container button{
	font-size:14px !important;
	width:100%;
	height:40px;
	margin: 3px 0;
	padding: 0 !important;
}
.register .buttons-container button:first-child{
	margin-top: 20px;
}
.register .buttons-container button:last-child{
	margin-bottom: 20px;
}
.register .btn-facebook{
	color: #3B5998 !important;
	border-color: #3B5998 !important;
}
.register .btn-google{
	color: #518EF8 !important;
	border-color: #518EF8 !important;
}

@media only screen and (min-width: 600px) {
	.register {
		height: 100vh;
		padding-bottom: 0;
		padding-top: 0;
	}
	.register .register-scroll {
		height: 100vh;
		bottom: 0;
	}
	.register .register-box{
		margin: 0;
		width: 400px;
		margin-left: auto;
		height: 100%;
		border-radius: 0;
		padding: 40px;
	}
}