.menu{
	background: #fff;
	padding: 20px;
	padding-bottom: 74px;
}
.menu ul{
	list-style:none;
	padding:0;
}
.menu ul li button{
	border:0;
	background:transparent;
	color: #483A3A;
	font-size:16px;
	font-weight:bold;
	line-height:2.2;
	display:flex;
	align-items: center;
	padding: 5px 0;
}
.menu p{
	color: #483A3A;
	font-size:14px;
}
.menu .account-btns{
	height: calc(100vh - 550px);
	width: 100%;
	display:flex;
}
.menu .account-btns div{ 
	margin:auto 0;
}
.menu .btn-link{
	color: #0AA09F;
	font-weight: bold;
	width: 100%;
    text-align: left;
}
.menu h2{
	color: #483A3A;
	font-weight: bold;
	margin-bottom: 40px;
}
.menu h2 a{
	font-weight: bold;
	color: #0AA09F;
	font-size: 16px;
	padding-left: 20px;
}
.menu label{
	font-weight:100;
	color: #483A3A;
	margin-top: 20px;
}
.menu .menu-icon{
	font-size: 34px;
	margin-right: 20px;
	color: #B1A1A1;
}
button:focus{
	outline:0;
}