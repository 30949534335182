.search{
	background: #fff;
	padding-top:20px;
}
.search .search-top{
	padding: 10.5px 20px;
	display:flex;
	background: rgba(255,255,255,0.9);
	z-index:999;
	position: relative;
}
.search .search-top svg{
	color: #0AA09F;
}
.search .search-container{
	position: relative;
	width: calc(100% - 40px);
    margin-right: 15px;
	margin:20px;
	padding:10px;
	margin-top:0;
	border-radius:10px;
	border: 1px solid #E6E2E2;
	box-shadow: 0px 5px 7px #00000029;
}
.search .search-input{
	width: 100%;
	height:39px;
	border:0;
	padding: 6px 40px;
	font-size: 16px;
	font-weight: bold;
	color: #483A3A;
	border-bottom:1px solid #E6E2E2;
}
.search .search-input::placeholder {
	color: #CBC0C0;
}
.search .search-input:focus{
	outline:0;
}
.search .search-icon{
	position:absolute;
	top: 12px;
    left: 10px;
}
.search .search-icon i{
	color: #B1A1A1;
	font-size:30px;
}
.search .close-search-icon{
	position:absolute;
	top: 18px;
    right: 10px;
}
.search .close-search-icon .menu-icon{
	color:#B1A1A1;
	font-size:20px;
}
.search .search-container .search-results{
	margin-top:20px;
}
.search .search-container .search-results ul{
	list-style:none;
}
.search .search-container .search-results ul li{
	font-size:16px;
	color:#483A3A;
	line-height:2;
}
.search .explore-search{
	padding:20px;
}
.search .explore-search h4{
	color: #483A3A;
	font-size:16px;
	font-weight:bold;
}
.search .explore-search .explore-btns-container{
	width:100%;
	height: 60px;
    padding-top: 15px;
	overflow-x:scroll;
}	
.search .explore-search .explore-btns{
	width:max-content;
}
.search .explore-search .explore-btns a{
	margin:0 10px;
}
.search .explore-search .explore-btns a:first-child{
	margin-left:0;
}
.search .explore-search .explore-btns a:last-child{
	margin-right:0;
}