.header{
	position: absolute;
	top:0;
	left:0;
	width:100%;
	z-index: 999;
}
.header.fixed{
	position: fixed;
}
.nav-top .pull-left{
	margin-right: auto;
}
.nav-top .pull-right{
	margin-left: auto;
}
.nav-top .nav-top-links{
	display:flex;
	list-style: none;
	padding:0;
	margin:0;
}
.nav-top .nav-top-links li button{
	color: #0AA09F;
	text-decoration: none;
	padding: 5px 20px;
	font-weight: bold;
	padding-bottom: 6px;
	font-size: 14px;
	height: 100%;
}
.nav-top{
	height: 60px;
	padding: 0 20px;
    background-color: #fff;
    display: flex;
    align-items: center;
    box-shadow: 0 3px 7px rgb(0 0 0 / 19%), 0 3px 31px rgb(0 0 0 / 23%);
}


/*HEADER DESKTOP*/

#navbar-top
{
  padding-top:30px;
  padding-left: 40px;
  padding-right: 40px;
  position: absolute;
  background-color: transparent;
  z-index: 99999;
  width: 100%;
  display: inline-block;
}
#navbar-top a{
  font-family: 'Fira Sans' !important;
}

#navbar-top2
{
  padding-top:30px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: transparent;
  z-index: 99999;
  width: 100%;
  display: inline-block;
  height: 87px;
}
#navbar-top a{
  font-family: 'Fira Sans' !important;
}

.logo{
  float: left;
}

.header-navbar {
  align-items: center;
  display: flex;
  float: right;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 15px;
}

.header-navbar-item{
  margin-left:30px;
}

.header-navbar-item a {
  color: #fff;
  font-weight: 700;
  text-decoration: none;
}

.header-navbar-item a:hover {
  color: #0AA09F;
  font-weight: 700;
  text-decoration: none;
}

.header-navbar-item a:active{
  color: #483A3A;
}

#navbar-top2 .header-navbar-item a {
  color: #483A3A;
}
#navbar-top2 .header-navbar-item a:hover {
  color: #0AA09F;
}
#navbar-top2 .header-navbar-item a:active{
  color: #0AA09F;
}

.header-navbar-item2 a{
  color: #fff;
  font-weight: 700;
  text-decoration: none;
  border: 2px solid #fff;
  border-radius: 40px;
  padding:10px 20px;
  background-color: transparent;
}

.header-navbar-item2 a:hover {
  background-color: #FFFFFF30;
  color:var(--larin-white);
  text-decoration: none;
}

.header-navbar-item2 a:active{
  color: #483A3A;
  border-color: #483A3A;
  background-color: transparent !important;
}

#navbar-top2 .header-navbar-item2 a{
  color: #0AA09F;
  font-weight: 700;
  text-decoration: none;
  border: 2px solid #0AA09F;
  border-radius: 40px;
  padding:10px 20px;
  background-color: transparent;
}
