.home{
	background: #fff;
}

.home .carousel-container{
	position: relative;
	margin-bottom: 60px;
}
/*
.home .carousel-indicators{
	margin-bottom: 5px;
}
.home .carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
.home .carousel.slide .carousel-item img{
	height: 500px;
	object-fit: cover;
}
.home .carousel-caption {
    position: absolute;
    bottom: 220px;
    left: 20px;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
}
.home .carousel.slide .carousel-caption h3{
	font-size: 30px;
	font-weight: bold;
	text-shadow: 0px 0px 10px #00000080;
	text-align: left;
	margin:0;
	line-height:1.2;
}
.home .carousel.slide .carousel-caption p{
	font-size: 30px;
	text-shadow: 0px 0px 10px #00000080;
	text-align: left;
	margin:0;
	line-height:1.2;
}
.home .carousel .info-bottom{
    position: absolute;
    bottom: 40px;
	left: 20px;
}
.home .carousel .info-bottom p{
	color: #fff;
	font-size: 12px;
	margin: 0;
}
.home .carousel .info-bottom .title{
	font-weight:bold;
}
.home .carousel .info-bottom p a{
	font-weight:bold;
	color: #0AA09F;
}
*/
.home .search-container{
	position: absolute;
	bottom: 150px;
	left: 20px;
	display:grid;
	width: calc(100% - 40px);
	z-index:9;
}
.home .search{
	width: 100%;
	border:0;
	border-radius: 2px;
	padding: 15px 20px;
	padding-left: 70px;
	font-size: 14px;
	font-weight: 400;
}
.home .search:focus{
	outline:0;
}
.home .search-icon{
	position:absolute;
	bottom:8px;
	left:20px;
}
.home .search-icon i{
	color: #B1A1A1;
	font-size:23px;
}
.home label{
	color: #fff;
	font-size: 14px;
	font-weight: 400;
}
.home .own-space{
	box-shadow: 0px 3px 6px #0000004D;
	border-radius: 5px;
	margin: 0 20px;
	margin-top: 20px;
	padding: 10px;
	display:flex;
	align-items:center;
	position:relative;
	max-height: 100px;
	transition: max-height 0.2s ease-out 0.6s, margin 0.2s ease-out 0.6s, padding 0.2s ease-out 0.6s, transform 1s ease-out;
}
.home .own-space.closed{
	transform: translateX(-150%);
	max-height:0;
	margin:0;
	padding:0;
}
.home .own-space img{
	width:80px;
	height:80px;
	max-height:80px;
	margin-right: 20px;
	object-fit:cover;
	border-radius: 50%;
}
.home .own-space .btn-close{
	position: absolute;
    right: 5px;
    top: 5px;
    border: 0;
    background: transparent;
    font-size: 10px;
    color: #B1A1A1;
}
.home .own-space h4{
	color: #483A3A;
	font-weight:bold;
	font-size: 14px;
	margin-bottom:5px;
	padding-right:15px;
}
.home .own-space p{
	color: #B1A1A1;
	font-size: 14px;
	margin:0;
	line-height:1.2;
}
.home .features .features-item{
	width:285px;
	padding-right: 20px;
}
.home .features .house-img{
	height: 120px;
	object-fit: cover;
	border-radius: 5px;
}
.home .features .house-location{
	margin:0;
	margin-top:20px;
	color: #483A3A;
	font-size: 14px;
	line-height: 1.3;
}
.home .features .house-name{
	margin:0;
	margin-bottom:10px;
	font-weight: bold;
	color: #483A3A;
	font-size: 14px;
	line-height: 1.3;
}
.home .features .house-price{
	margin:0;
	font-weight: 500;
	color: #0AA09F;
	font-size: 14px;
	line-height: 1.3;
}
.home .features .house-rating{
	margin:0;
	margin-bottom:20px;
	color: #483A3A;
	font-size: 14px;
	line-height: 1.3;
}
.home .explore-zone{
	padding-bottom:0;
}
.home .features .house-rating svg{
	color: #0AA09F;
}
.home .features .house-rating span{
	color: #CBC0C0;
	font-size: 14px;
	line-height: 1.3;
}
.home .explore-zone .zones-scroll{
	overflow-x: auto;
    margin-right: -20px;
	padding-bottom:20px;
}
.home .explore-zone .zones-scroll::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
}
.home .explore-zone .zones-scroll::-webkit-scrollbar-track {
    background-color: #fff;
}
.home .explore-zone .zones-scroll::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
}
.home .explore-zone .zones-scroll::-webkit-scrollbar-button {
    display:none;
}
.home .explore-zone .zones-container{
	display: flex;
    width: fit-content;
}
.home .explore-zone .zone-item{
	width:50vw;
	padding-right: 20px;
}
.home .explore-zone .zone-img-container{
	position:relative;
}
.home .explore-zone .zone-img-container p{
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
	text-align: center;
    letter-spacing: 1px;
	margin:0;
}
.home .explore-zone .zone-img{
	height:120px;
	object-fit:cover;
	border-radius:5px;
}
.home .explore-zone .zone-district{
	margin: 0;
    margin-top: 20px;
    color: #483A3A;
    font-size: 16px;
    line-height: 1.3;
}
.home .explore-zone .zone-desc{
	margin: 0;
    font-weight: bold;
    color: #483A3A;
    font-size: 16px;
    line-height: 1.3;
}
.home .blog-title{
	color: #483A3A;
	font-size: 16px;
	font-weight: bold;
	line-height: 1.2;
	margin:0;
}
.home .blog-subtitle{
	color: #0AA09F;
	font-size: 13px;
	font-weight: bold;
	line-height: 1.2;
	margin:0;
	margin-top: 10px;
}
.home .add-home{
	position:relative;
}
.home .add-home .add-home-img{
	height: 200px;
    object-fit: cover;
    border-radius: 5px;
}
.home .add-home .add-home-container{
	position:absolute;
	left:0;
	top:0;
	width:calc(100% - 40px);
	height:calc(100% - 40px);
	background: rgba(0, 0, 0, 0.5);
	margin:20px;
	padding:20px;
	border-radius:5px;
}
.home .add-home .add-home-container h3{
	font-weight:bold;
	font-size:20px;
	color:#fff;
	margin-bottom:5px;
	line-height:1.2;
}
.home .add-home .add-home-container p{
	font-size:14px;
	color:#fff;
	line-height:1.2;
}
.home .add-home .add-home-container a{
	position:absolute;
	bottom:20px;
}
.home .services .services-scroll{
	overflow-x: auto;
    margin-right: -20px;
}
.home .services .services-container{
	display: flex;
    width: fit-content;
}
.home .services .service-item{
	width:50vw;
	padding-right: 20px;
}
.home .services .service-img{
	height:120px;
	object-fit:cover;
	border-radius:5px;
}
.home .services .service-location{
	margin: 0;
    margin-top: 20px;
    color: #483A3A;
    font-size: 14px;
    line-height: 1.3;
}
.home .services .service-name{
	margin: 0;
    font-weight: bold;
	margin-top:15px;
    color: #483A3A;
    font-size: 14px;
    line-height: 1.3;
}
.home .services .service-price{
	margin:0;
	font-weight: 500;
	color: #0AA09F;
	font-size: 14px;
	line-height: 1.3;
}
.home .services .service-rating{
	margin:0;
	margin-bottom:20px;
	color: #483A3A;
	font-size: 14px;
	line-height: 1.3;
}
.home .services .service-rating svg{
	color: #0AA09F;
}
.home .services .service-rating span{
	color: #CBC0C0;
	font-size: 14px;
	line-height: 1.3;
}
.home .extra p.subtitle-default{
	font-size: 18px;
	margin-top:20px;
}
.hscroll-fake{
	height:600px;
	width:100%;
}  
.hscroll-container{
	overflow-y: hidden;
	overflow-x: scroll;
	position: absolute;
	left: 0;
	width: 100vw;
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
}

.hscroll-container::-webkit-scrollbar {
	display: none;
}

.hscroll-container .container{
display: flex;
}
.hscroll-container .hscroll-1{
width: 600px;
margin-right: 40px;
}
.hscroll-container .hscroll-1 h2{
margin-top: 8px;
margin-bottom: 48px;
}
.hscroll-container .hscroll-1 p{
width: 600px;
font-size: 25px;
line-height: 1.3;
margin-right: 50px;
}
.hscroll-container .hscroll-2{
margin-right: 40px;
}
.hscroll-container .hscroll-3{
margin-right: 40px;
}
.hscroll-container .hscroll-3 p{
font-size: 25px;
line-height: 1.3;
margin-top: 30px;
}
.hscroll-container .hscroll-4{
margin-right: 40px;
}
.hscroll-container .hscroll-5 p{
width: 600px;
font-size: 25px;
line-height: 1.3;
margin-right: 50px;
}
.hscroll-container .hscroll-5 img{
margin-top:10px;
}
.hscroll-arrow-left{
	position: absolute;
	left: 20px;
	transform: translateY(270px)rotate(180deg);
	width: 60px;
	height: 60px;
	border-radius: 50%;
	border: 0;
	background: #fff;
	color: #B1A1A1;
	box-shadow: -4px 3px 6px #00000033;
	display:none;
}
.hscroll-arrow-right{
	position: absolute;
	right: 20px;
	transform: translateY(270px);
	width: 60px;
	height: 60px;
	border-radius: 50%;
	border: 0;
	background: #fff;
	color: #B1A1A1;
	box-shadow: -4px 3px 6px #00000033;
}

.hscroll-arrow-left:hover,
.hscroll-arrow-right:hover,
.hscroll-arrow-left:focus,
.hscroll-arrow-right:focus{
	outline:0;
	background:#F0F0F0;
}
.hscroll-arrow-left:focus,
.hscroll-arrow-right:focus{
	background: #483A3A;
}
.notes{
	font-size: 14px;
}
@media only screen and (min-width: 600px) {
	.home .search-container{
		bottom: 230px;
		left: 15%;
		width: 70%;
	}
	.home .features .house-img{
		height: 190px;
	}
	.home .services .service-img{
		height: 190px;
	}
	.home .explore-zone .zone-img{
		height: 190px;
	}
	.home .features{
		overflow-x: auto;
		padding-bottom:0;
	}
	.home .features .show-desktop{
		display: flex !important;
		width: fit-content;
	}
	.home .services .service-item{
		width: 285px;
	}
	.home .explore-zone .zone-item{
		width: 285px;
	}
	.home .extra{
		width: 60%;
	}

	.home .add-home{
		height: 200px;
		padding:0;
	}
	.home .add-home .add-home-container{
		margin: 0;
		width: 100%;
		padding: 0;
		position: unset;
		height: 200px;
		background:none;
	}
	.home .add-home .add-home-img{
		position: absolute;
		left:0;
		width:100%;
		height:100%;
		top:0;
	}
	.home .add-home .add-home-text{
		height:100%;
	}
	.home .add-home .add-home-text .row{
		height:100%;
	}
	.home .add-home .add-home-text .col-md-4{
		height:100%;
		display:flex;
		align-items:center;
		justify-content:center;
	}
	.home .add-home .add-home-text .col-text{
		background:#fff;
	}
	.home .add-home .add-home-text .col-text h3{
		color: #483A3A;
		font-size:30px;
		font-weight: bold;
	}
	.home .add-home .add-home-text .col-text p{
		color: #483A3A;
		font-size:20px;
		margin:0;
	}
	.home .col-triangle1{
		justify-content: end !important;
		overflow: hidden;
	}
	.home .col-triangle2{
		justify-content: start !important;
		overflow: hidden;
	}
	.home .triangle1{
		height:200%;
		width: 150px;
		transform: rotate(30deg) translateX(101px);
		background: #fff;
	}
	.home .triangle2{
		height:200%;
		width: 150px;
		transform: rotate(30deg) translateX(-101px);
		background: #fff;
	}
	.home .explore-zone .zone-district{
		font-size:20px;
	}
	.home .explore-zone .zone-desc{
		font-size:20px;
	}
	.home .services .service-name{
		font-size:20px;
	}
}
@media only screen and (max-width: 600px) {
	.hscroll-container .hscroll-1{
		width:280px;
		min-width:280px;
	}
	.hscroll-container .hscroll-1 h2{
		margin-bottom: 21px;
	}
	.hscroll-container .hscroll-1 img{
		width:100%;
	}
	.hscroll-container .hscroll-2{
		width:280px;
		min-width:280px;
	}
	.hscroll-container .hscroll-2 img{
		width:100%;
	}
	.hscroll-container .hscroll-3{
		width:280px;
		min-width:280px;
	}
	.hscroll-container .hscroll-3 img{
		width:100%;
	}
	.hscroll-container .hscroll-4{
		width:280px;
		min-width:280px;
	}
	.hscroll-container .hscroll-4 img{
		width:100%;
	}
	.hscroll-container .hscroll-5{
		width:295px;
		min-width:295px;
		padding-right: 15px;
	}
	.hscroll-container .hscroll-5 p{
		width:100%;
	}
	.hscroll-container .hscroll-5 img{
		width:100%;
	}
	.hscroll-container p{
		font-size:17px !important;
	}
	.hscroll-fake{
		height: 480px !important;
	}
	.notes{
		padding: 0 20px;
	}
}