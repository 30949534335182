.register-success{
	height: calc(100vh - 70px);
	background-size: cover !important;
	overflow-y: auto;
	position:relative;
}
.register-success .text-container{
	padding:40px;
}
.register-success .img-container{
	height: 35vh;
	position:relative;
}
.register-success img{
	height: 100%;
	object-fit:cover;
}
.register-success .img-container p{
	margin:0;
	font-size:30px;
	font-weight:bold;
	color:#fff;
	text-align:center;
	top:calc(50% - 20px);
}
.register-success .img-container .img-overlay{
	position:absolute;
	top:0;
	width:100%;
	height:100%;
	background: rgba(0,0,0,0.3);
	display:flex;
	align-items:center;
	justify-content:center;
}
.register-success .register-box{
	position:absolute;
	bottom:0;
	background: #fff;
	margin:20px;
	padding: 20px;
	box-shadow: -4px 3px 6px #00000029;
	border-radius: 4px;
	overflow-y:auto;
	max-height: calc(100% - 40px);
}
.register-success .subtitle{
	color: #483A3A;
	font-size:16px;
	margin:0;
	margin-bottom:20px;
	text-align:center;
}
.register-success .subtitle span{
	font-weight: bold;
}
.register-success .subtitle .btn-link{
	color: #0AA09F;
	font-weight:bold;
	padding:0;
}
.register-success .buttons-container{
	text-align:center;
}
.register-success .buttons-container button{
	font-size:14px !important;
	width:60%;
	height:40px;
	margin: 3px 0;
}
@media only screen and (min-width: 600px) {
	.register-success .buttons-container button{
		width: auto;
	}
}