.register-email{
	border-bottom: 1px solid #CBC0C0;
	margin-bottom: 20px;
}
.birthdate-container{
	margin-top:20px;
	margin-bottom: 10px;
}
.birthdate{
	display:flex;
	position:relative;
}
.birthdate .birthdate-select{
	width:calc(100% / 3);
	margin: 5px;
	z-index: 999;
}
.birthdate .birthdate-select:first-child{
	margin-left:0;
}
.birthdate .birthdate-select:last-child{
	margin-right:0;
}
.warning-text{
	color: #483A3A;
	font-size: 12px;
}
.register-email .btn-primary{
	font-size:16px !important;
	font-weight:bold;
	padding: 15px 30px !important;
	width: 90%;
	margin-bottom: 40px;
}
.align-center{
	display:flex;
	justify-content:center;
}
.checkbox-container{
	line-height: 15px;
	margin-bottom:20px;
}
.register-error{
	color: red;
	font-size: 12px;
	margin-bottom:10px;
}
.register-error2{
	color: red;
	font-size: 12px;
	margin-top:15px;
	margin-bottom:0;
}
.register-accept{
	margin-top: -33px;
    margin-bottom: -41px;
    background-color: #fff;
    transform: translateZ(10px);
}
.register-accept h3{
	color: #483A3A;
	font-weight: bold;
	font-size:20px;
}
.register-accept h4{
	color: #483A3A;
	font-weight: bold;
	font-size:16px;
}
.register-accept .subtitle{
	color: #483A3A;
	font-size:14px;
	margin:0;
	margin-bottom:20px;
}
.register-accept .subtitle span{
	color: #0AA09F;
	font-weight: bold;
}
.register-accept .subtitle .btn-link{
	color: #0AA09F;
	font-weight:bold;
	padding:0;
}
.register-accept .buttons-container{
	display:flex;
}
.register-accept .buttons-container button{
	font-size:14px !important;
	width:100%;
	height:50px;
	margin: 3px 0;
	padding: 0 !important;
}
.register-accept .buttons-container button:first-child{
	margin-right:10px;
	margin-bottom:20px;
	margin-top:0;
}
.register-accept .buttons-container button a{
	color: #0AA09F;
}
.register-accept .buttons-container button:last-child{
	margin-left:10px;
	margin-bottom:20px;
	margin-top:0;
}