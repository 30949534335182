.login{
	height: calc(100vh - 70px);
	background-size: cover !important;
}
.login .login-box{
	background: #fff;
	margin:20px;
	position:absolute;
	bottom:70px;
	left:0;
	width: calc(100% - 40px);
	padding: 20px;
	box-shadow: -4px 3px 6px #00000029;
	border-radius: 4px;
}
.login .background-caption{
	position:absolute;
	top:-50px;
	left: 0;
	color: #fff;
	font-size:12px;
	text-shadow: 0px 0px 6px #000000A8;
}
.login h3{
	color: #483A3A;
	font-weight: bold;
	font-size:20px;
}
.login .subtitle{
	color: #483A3A;
	font-size:14px;
	margin:0;
}
.login .subtitle span{
	color: #0AA09F;
	font-weight: bold;
}
.login .subtitle .btn-link{
	color: #0AA09F;
	font-weight:bold;
	padding:0;
}
.login .btn-primary{
	font-size:16px !important;
	width:calc(100% - 60px);
	height:50px;
	margin: 10px 0;
	margin-left:30px;
}
@media only screen and (min-width: 600px) {
	.login {
		height: 100vh;
		padding-bottom: 0;
		padding-top: 0;
	}
	.login .login-box{
		margin: 0;
		width: 400px;
		bottom: 0;
		right: 0;
		left: unset;
		height: 100%;
		border-radius: 0;
		padding: 40px;
	}
}