@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;600&display=swap');

body{
  overflow-x: hidden;
  width: 100vw;
}

body *{
	font-family: 'Fira Sans',
    sans-serif;
}
.main{
	padding-top: 60px;
	padding-bottom: 70px;
	overflow-y:auto;
  overflow-x:hidden;
	height:100vh;
}
a:hover{
  text-decoration: none !important;
}
b{
  font-weight: bold !important;
}
p {
  font-weight: 300;
}
.text-link{
  color: #0AA09F;
  font-weight: bold;
}
.text-link:hover{
  color: #0AA09F;
  font-weight: bold;
  text-decoration: none;
}
.logo{
  width:150px;
}

/* MARGINS */
.mb-0{margin-bottom: 0;}
.mt-0{margin-top: 0;}
.mt-20{margin-top: 20px;}
.mt-30{margin-top: 30px;}
.mb-30{margin-bottom: 30px;}
.mt-40{margin-top: 40px;}
.mt-60{margin-top: 60px;}
.mb-60{margin-bottom: 60px;}
.mt-80{margin-top: 80px;}
.mt-100{margin-top: 100px;}
.mt-200{margin-top: 200px;}

.btn-primary{
	border: 0 !important;
	border-radius: 50px !important;
	background-color: #0AA09F !important;
	color: #fff !important;
	font-size: 14px !important;
    padding: 7px 20px !important;
	font-weight: bold;
}
.btn-secondary{
	border: 2px solid #0AA09F !important;
	border-radius: 50px !important;
	background-color: transparent !important;
	color: #0AA09F !important;
	font-size: 14px !important;
    padding: 7px 20px !important;
	font-weight: bold;
}
.btn-link{
	border:0;
	background: transparent;
}
/* for Opera,webkit chrome browsers */
input::-webkit-input-placeholder { 
    color: #B1A1A1;
	opacity:100;
}
/*firefox 19+ versions*/
input::-moz-placeholder {
    color: #B1A1A1;
	opacity:100;
}
/*IE  versions*/
input::-ms-placeholder {
    color: #B1A1A1;
	opacity:100;
}
/*Latest modern browsers */

input::placeholder {
    color: #B1A1A1;
	opacity:100;
}
.btn-view-more{
	color: #0AA09F;
	font-weight: bold;
	font-size: 14px;
}
.btn-view-more svg{
	color: #CBC0C0;
	margin-left: 10px;
	font-size: 16px;
}
h2.title-default{
	color: #483A3A;
	font-weight: bold;
	font-size: 24px;
}
h3.title-default{
	color: #483A3A;
	font-weight: bold;
	font-size: 30px;
  margin-bottom: 10px;
  line-height: 1.5;
}
h4.title-default{
	color: #483A3A;
	font-weight: bold;
	font-size: 16px;
}
p.subtitle-default{
	color: #483A3A;
	font-size: 20px;
	line-height:1.3;
  font-weight: 200;
}
.section{
	padding:20px;
}
.section-margin{
  margin-top:20px;
  margin-bottom:20px;
}
.float-left{
	float:left;
}
.float-right{
	float:right;
}
.dnone{
	display:none;
}
.hidden{
	visibility:hidden;
}
.btn-white{
	border:1px solid #fff;
	border-radius: 20px;
	color: #fff;
	background: rgba(255,255,255,0.3);
	font-size:14px;
	padding: 7px 20px;
}
.field label{
	color: #B1A1A1;
	font-size: 14px;
}
.field.label-float {
  display: flex;
  flex-flow: column-reverse;
  margin-bottom: 1em;
}
/**
* Add a transition to the label and input.
* I'm not even sure that touch-action: manipulation works on
* inputs, but hey, it's new and cool and could remove the 
* pesky delay.
*/
.label-float label, .label-float input {
  transition: all 0.2s;
  touch-action: manipulation;
}

.label-float input {
  font-size: 14px;
  border: 0;
  border-bottom: 1px solid rgba(0,0,0,.1);
  font-family: inherit;
  -webkit-appearance: none;
  border-radius: 0;
  padding: 0;
  cursor: text;
  height:32px;
  z-index: 9;
  background: transparent;
}

.label-float input:focus {
  outline: 0;
  border-bottom: 1px solid #666;
}

.label-float label {
  font-size:12px;
  color: #B1A1A1;
}
/**
* Translate down and scale the label up to cover the placeholder,
* when following an input (with placeholder-shown support).
* Also make sure the label is only on one row, at max 2/3rds of the
* field—to make sure it scales properly and doesn't wrap.
*/
.label-float input:placeholder-shown + label {
  cursor: text;
  max-width: 66.66%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform-origin: left bottom;
  transform: translate(0, 1.6rem) scale(1.15);
}
/**
* By default, the placeholder should be transparent. Also, it should 
* inherit the transition.
*/
::-webkit-input-placeholder {
  opacity: 0;
  transition: inherit;
}
/**
* Show the placeholder when the input is focused.
*/
.label-float input:focus::-webkit-input-placeholder {
  opacity: 1;
}
/**
* When the element is focused, remove the label transform.
* Also, do this when the placeholder is _not_ shown, i.e. when 
* there's something in the input at all.
*/
.label-float input:not(:placeholder-shown) + label,
.label-float input:focus + label {
  transform: translate(0, 0.3rem) scale(1);
  cursor: pointer;
}

/* Customize the label (the checkbox-container) */
.checkbox-container {
  position: relative;
  padding-left: 45px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  color: #483A3A;
  line-height: 35px;
  -webkit-user-select: none;
  user-select: none;
  min-height: 35px;
  height: auto;
  display: flex;
  align-items: center;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 35px;
  width: 35px;
  border-radius:4px;
  background-color: #fff;
  border: 1px solid #CBC0C0;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #0AA09F;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
	left: 14px;
    top: 9px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.highlight-title {
  background-color: #0aa09f;
  background-color: #0aa09f;
  border-radius: 50px;
  color: #fff;
  padding: 2px 10px;
}

.footer{
	border-top: 1px solid #CBC0C0;
}
.footer .accordion{
	margin-top:20px;
}
.footer .accordion .card{
	border:0;
	background: transparent;
	border-radius:0;
	border-bottom: 1px solid #CBC0C0 !important;
}
.footer .accordion .card-header{
	border:0;
	background:transparent;
	padding:20px 0;
}
.footer .accordion .card-body{
	padding: 5px 0;
}
.footer .accordion .card-body ul{
	list-style:none;
	padding:0;
}
.footer .accordion .card-body li{
	font-size:16px;
	color:#0AA09F;
	font-weight:bold;
}
.footer .accordion .card-body li a{
	color:#0AA09F;
	font-weight:bold;
}
.footer .accordion .card-body li a:hover{
	color:#0AA09F;
	font-weight:bold;
}
.footer .accordion .btn-link{
	width: 100%;
    padding: 0;
    text-align: left;
    font-size: 16px;
    color: #483A3A;
    font-weight: bold;
}
.footer .accordion .btn-link:focus{
	outline:0;
	box-shadow: none;
	text-decoration: none;
}
.footer .accordion .arrow-div{
	position: absolute;
    right: 0;
    top: 9px;
    font-size: 30px;
    color: #B1A1A1;
}
.footer .accordion .arrow-div.arrow-up{
	transform: rotate(180deg);
}
.footer .footer-links{
	margin: 20px 0;
    font-weight: bold;
    color: #483A3A;
}
.footer .footer-links a{
    font-weight: bold;
    color: #0AA09F;
}
.footer .logo-icon{
	filter: brightness(0)contrast(0.1);
}
.footer.show-desktop ul li a{
  color: #0aa09f;
}
.show-desktop{
  display:none !important;
}
@media only screen and (min-width: 600px) {
  .show-desktop{
    display:block !important;
  }
  .hide-desktop{
    display:none !important;
  }
  .main{
    padding-top:0;
    padding-bottom:0;
    height: auto;
  }
  .section{
    padding: 20px 0;
  }
}
@media only screen and (max-width: 600px) {
  .container{
    padding:0 !important;
  }
}
/* ICONS */
@font-face {
  font-family: 'icomoon';
  src: local('icomoon'), url(./fonts/icomoon.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-accept-icon:before {
  content: "\e900";
}
.icon-arrow-link-icon:before {
  content: "\e901";
}
.icon-arrow-down-icon:before {
  content: "\e902";
}
.icon-arrow-up-icon:before {
  content: "\e903";
}
.icon-arrow-left-icon:before {
  content: "\e904";
}
.icon-arrow-right-icon:before {
  content: "\e905";
}
.icon-close-btt:before {
  content: "\e906";
}
.icon-plus-icon:before {
  content: "\e907";
}
.icon-delete-icon:before {
  content: "\e908";
}
.icon-menu-icon:before {
  content: "\e909";
}
.icon-house-icon:before {
  content: "\e90a";
}
.icon-account-icon:before {
  content: "\e90b";
}
.icon-bed-icon:before {
  content: "\e90c";
}
.icon-shared-bed-icon:before {
  content: "\e90d";
}
.icon-calendar-icon:before {
  content: "\e90e";
}
.icon-profile-icon:before {
  content: "\e90f";
}
.icon-friends-icon:before {
  content: "\e910";
}
.icon-cosmetics:before {
  content: "\e911";
}
.icon-envelope-icon:before {
  content: "\e912";
}
.icon-flag-icon:before {
  content: "\e913";
}
.icon-folder-icon:before {
  content: "\e914";
}
.icon-heart-icon:before {
  content: "\e915";
}
.icon-share-icon:before {
  content: "\e916";
}
.icon-info-icon:before {
  content: "\e917";
}
.icon-facebook-icon:before {
  content: "\e918";
}
.icon-larin-home:before {
  content: "\e919";
}
.icon-larin-icon:before {
  content: "\e91a";
}
.icon-larin-logo-color-mob:before {
  content: "\e91b";
}
.icon-limit-icon:before {
  content: "\e91c";
}
.icon-location-icon:before {
  content: "\e91d";
}
.icon-lock-icon:before {
  content: "\e91e";
}
.icon-market-icon:before {
  content: "\e91f";
}
.icon-message-nav-icon:before {
  content: "\e920";
}
.icon-new-conversation-icon:before {
  content: "\e921";
}
.icon-present-icon:before {
  content: "\e922";
}
.icon-revista-icon:before {
  content: "\e923";
}
.icon-search-icon:before {
  content: "\e924";
}
.icon-shopping-cart_icon:before {
  content: "\e925";
}
.icon-shopping-icon:before {
  content: "\e926";
}
.icon-travel-icon:before {
  content: "\e927";
}
.icon-trust-confirm-icon:before {
  content: "\e928";
}
.icon-estrela-mob:before {
  content: "\e929";
}
.icon-quotes-icon:before {
  content: "\e92a";
}