.bottom{
	position:fixed;
	bottom:0;
	left:0;
	width:100%;
}
.nav-bottom{
	width: 100%;
	height:70px;
	background: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 3px 31px rgb(0 0 0 / 23%);
    padding-bottom: 0;
    padding-bottom: env(safe-area-inset-bottom);
    padding-bottom: constant(safe-area-inset-bottom);
    z-index: 999;
	display:flex;
	align-items: center;
}
.nav-bottom .btn-nav-bottom{
	width: 100%;
    background-color: transparent;
    border: 0;
    position: relative;
	padding:0;
	margin-top: 10px;
}
.nav-bottom .btn-nav-bottom:focus{
	outline:0;
}
.nav-bottom p{
	margin:0;
	color: #B1A1A1;
	font-size:13px;
	position:absolute;
	left:0;
	bottom:5px;
	text-align: center;
	width:100%;
}
.nav-bottom .container{
	height: 100%;
}
.nav-bottom .row{
	height:100%;
}
.nav-bottom .menu-icon{
	font-size:34px;
	color: #B1A1A1;
}
.nav-bottom .menu-icon.icon-close-btt{
	font-size: 26px;
}
.nav-bottom .btn-nav-bottom.active .menu-icon{
	color: #0AA09F;
}
.logo-bottom{
	height:100%;
	display:flex;
	align-items:center;
	justify-content: center;
}
.logo-bottom .menu-icon{
	color: #0AA09F;
	font-size:45px;
}
@media only screen and (min-width: 600px) {
	#main-bottom{
		display:none;
	}
}