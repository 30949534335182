
@media only screen and (min-width: 600px) {
  .footer.show-desktop{
		padding:0;
		padding-top: 40px;
	}
	.footer.show-desktop h3{
		font-size: 16px;
		font-weight:200;
		color: #483A3A;
		margin-bottom: 30px;
	}
	.footer.show-desktop ul{
		list-style: none;
		padding:0;
		margin:0;
	}
	.footer.show-desktop ul li{
		color: #0AA09F;
		font-size: 16px;
		font-weight: bold;
		line-height: 2;
	}
	.footer.show-desktop .footer-bottom{
		display:flex;
		justify-content: space-between;
	}
	.footer.show-desktop .footer-bottom-left{
		display:flex;
		align-items:center;
		font-size: 16px;
		font-weight:200;
		color: #483A3A;
	}
	.footer.show-desktop .footer-bottom-left .logo-icon{
		width: 25px;
		margin-right:10px;
	}
}