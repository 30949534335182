.blog{
	background: #fff;
	padding:0;
}
.blog .blog-top{
	padding: 10.5px 20px;
	display:flex;
	background: rgba(255,255,255,0.9);
	z-index:999;
	position: relative;
}
.blog .blog-top.sticky{
	position: sticky;
	top:0;
	box-shadow: 0 3px 7px rgb(0 0 0 / 19%), 0 3px 31px rgb(0 0 0 / 23%);
}
.blog .blog-top .btn-secondary{
	width:94px;
	height:39px;
	padding: 7px 0px !important;
	text-align:center;
}
.blog .blog-top .icon{
	font-size:25px;
	padding:0 10px;
	color: #B1A1A1;
}
.blog .blog-top .icon:last-child{
	padding-right:0;
}
.blog .btn-back{
	color: #0AA09F !important;
    font-size: 24px !important;
    margin-top: 4px;
    margin-bottom: -4px;
    margin-right: 10px;
    padding: 0 !important;
    background: transparent !important;
}
.blog .search-container{
	position: relative;
	width: calc(100% - 133px);
    margin-right: 15px;
}
.blog .search{
	width: 100%;
	height:39px;
	border:0;
	border-radius: 30px;
	padding: 6px 20px;
	padding-left: 60px;
	font-size: 14px;
	font-weight: 400;
	border:1px solid #E6E2E2;
}
.blog .search::placeholder {
	color: #CBC0C0;
}
.blog .search:focus{
	outline:0;
}
.blog .search-icon{
	position:absolute;
	bottom:2px;
	left:20px;
}
.blog .search-icon i{
	color: #0AA09F;
	font-size:23px;
}
.blog .react-tabs{
	padding: 20px;
	clear:both;
}
.blog .tab-scroll{
	overflow-y:auto;
}
.blog .react-tabs .react-tabs__tab-list{
	border-color: #CBC0C0;
	width:max-content;
}
.blog .react-tabs .react-tabs__tab:first-child{
	padding-left:0;
}
.blog .react-tabs .react-tabs__tab:last-child{
	padding-right:0;
}
.blog .react-tabs .react-tabs__tab{
	border:0;
	text-align: center;
	bottom:0;
	color: #483A3A;
	font-weight: bold;
	position:relative;
	font-size: 14px;
}
.blog .react-tabs .react-tabs__tab span{
	background: #0AA09F;
	border-radius:50%;
	padding: 3px 4px;
	font-size:10px;
	color:#fff;
	font-weight:400;
}
.blog .react-tabs .react-tabs__tab--selected{
	color: #0AA09F;
	font-weight:bold;
}
.blog .react-tabs .react-tabs__tab--selected:after{
	content: '';
    height: 4px;
    width: calc(100% - 24px);
    position: absolute;
    bottom: -1px;
    left: 12px;
    background: #0AA09F;
}
.blog .react-tabs .react-tabs__tab--selected:first-child:after{
    width: calc(100% - 12px);
    left: 0;
}
.blog .react-tabs .react-tabs__tab--selected:last-child:after{
    width: calc(100% - 12px);
    left: 12px;
}
.blog .blog-featured-post .blog-featured-img{
	height:310px;
	width: calc(100% + 40px);
	margin-left: -20px;
	object-fit: cover;
	margin-bottom:15px;
	margin-top: -20px;
}
.blog .blog-featured-post .blog-featured-date{
	color: #483A3A;
	font-size: 14px;
	margin-bottom:15px;
}
.blog .blog-featured-post .blog-featured-title{
	color: #483A3A;
	font-size: 30px;
	font-weight: bold;
	line-height: 1.2;
	position:relative;
	margin:0;
	margin-bottom: 40px;
}
.blog .blog-featured-post .blog-featured-subtitle{
	color: #0AA09F;
	font-size: 20px;
	font-weight: bold;
	line-height: 1;
	margin-bottom: 5px;
}
.blog .blog-featured-post .blog-featured-title:after{
	content:'';
	position:absolute;
	left:0;
	bottom:-15px;
	height: 3px;
	width: 20px;
	background: #483A3A;
}
.blog .blog-featured-post .blog-featured-desc{
	color: #483A3A;
	font-size: 14px;
	line-height: 1.2;
	margin-bottom:15px;
}
.blog .blog-featured-post .blog-featured-desc a{
	color: #0AA09F;
}
.blog .blog-featured-post .blog-featured-author{
	color: #483A3A;
	font-size: 12px;
	margin-bottom:30px;
}
.blog .blog-featured-post .blog-featured-author b{
	margin-left: 5px;
}
.blog .blog-posts{
	margin-top:20px;
	padding:0;
}
.blog .blog-posts .blog-img{
	height: 106px;
	object-fit: cover;
	border-radius: 5px;
}
.blog .blog-posts .blog-date{
	color: #483A3A;
	font-size:12px;
	margin: 10px 0;
	line-height: 1.2;
}
.blog .blog-posts .blog-title{
	color: #483A3A;
	font-size: 16px;
	font-weight: bold;
	line-height: 1.2;
	margin:0;
}
.blog .blog-posts .blog-subtitle{
	color: #0AA09F;
	font-size: 13px;
	font-weight: bold;
	line-height: 1.2;
	margin:0;
}
.blog .blog-posts .blog-author{
	color: #483A3A;
	font-size: 12px;
	margin-top: 15px;
	line-height: 1.2;
}
.blog .blog-posts .btn-div{
	margin:20px 0;
	width:100%;
	text-align:center;
}
.blog .blog-zone{
	padding: 20px 0;
	padding-bottom:0;
}
.blog .blog-zone .zones-scroll{
	overflow-x: auto;
    margin-right: -20px;
	padding-bottom:20px;
}
.blog .blog-zone .zones-container{
	display: flex;
    width: fit-content;
}
.blog .blog-zone .zone-item{
	width:50vw;
	padding-right: 20px;
}
.blog .blog-zone .zone-img-container{
	position:relative;
}
.blog .blog-zone .zone-img-container p{
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
	text-align: center;
    letter-spacing: 1px;
	margin:0;
}
.blog .blog-zone .zone-img{
	height:120px;
	object-fit:cover;
	border-radius:5px;
}
.blog .blog-zone .zone-district{
	margin: 0;
    margin-top: 20px;
    color: #483A3A;
    font-size: 16px;
    line-height: 1.3;
}
.blog .blog-zone .zone-desc{
	margin: 0;
    font-weight: bold;
    color: #483A3A;
    font-size: 16px;
    line-height: 1.3;
}
.blog .add-home{
	position:relative;
	padding:20px 0;
}
.blog .add-home .add-home-img{
	height: 200px;
    object-fit: cover;
    border-radius: 5px;
}
.blog .add-home .add-home-container{
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:calc(100% - 40px);
	background: rgba(0, 0, 0, 0.5);
	margin:20px 0;
	padding:20px;
	border-radius:5px;
}
.blog .add-home .add-home-container h3{
	font-weight:bold;
	font-size:20px;
	color:#fff;
	margin-bottom:5px;
	line-height:1.2;
}
.blog .add-home .add-home-container p{
	font-size:14px;
	color:#fff;
	line-height:1.2;
}
.blog .add-home .add-home-container a{
	position:absolute;
	bottom:20px;
}
.blog .blog-posts .service-name{
	margin:0;
	margin-top:15px;
	font-weight: bold;
	color: #483A3A;
	font-size: 14px;
	line-height: 1.3;
}
.blog .blog-col{
	margin-bottom: 20px;
}
@media only screen and (min-width: 600px) {
	.blog .react-tabs{
		padding: 0px;
		margin-top: 40px;
	}
	.blog .react-tabs .react-tabs__tab{
		font-size:16px;
	}
	.blog-posts .blog-featured-post .blog-featured-img{
		height:500px;
		width: 50vw;
		margin-left: 0px;
		object-fit: cover;
		margin-bottom:15px;
		margin-top: 0px;
	}
	.blog-posts .blog-featured-post .bg-white-transform{
		width: 50vw;
		left: 0;
		top: 475px;
	}
	.blog .blog-posts .blog-img{
		height:250px;
	}
	.blog .blog-posts .blog-subtitle{
		font-size: 20px;
		margin-bottom: 10px;
	}
	.blog .blog-posts .blog-title{
		font-size: 24px;
	}
	.blog .blog-posts .blog-popular .blog-popular-item{
		padding-bottom:10px;
		padding-top:10px;
		border-bottom: 1px solid #CBC0C0;
	}
	.blog .blog-posts .blog-popular{
		position:relative;
	}
	.blog .blog-posts .blog-popular:after {
		content: '';
		position: absolute;
		left: 0;
		top: -5px;
		height: 3px;
		width: 20px;
		background: #483A3A;
	}
	.blog .blog-posts .blog-zones{
		margin-top: 80px;
	}
	.blog .blog-posts .blog-zones .zone-district-desc{
		width: calc(100% - 120px);
		padding-left: 15px;
	}
	.blog .blog-posts .blog-zones .zone-img-container{
		width:120px;
	}
	.blog .blog-posts .blog-zones .zone-img{
		height:120px;
		object-fit: cover;
		border-radius:50%;
	}
	.blog .blog-posts .blog-zones .zone-item a{
		display:flex;
		align-items:center;
	}
	.blog .blog-posts .blog-zones .zone-district{
		font-size:20px;
		color: #483A3A;
		margin-bottom:5px;
	}
	.blog .blog-posts .blog-zones .zone-desc{
		font-size:20px;
		color: #483A3A;
		font-weight: bold;
		margin-bottom:0;
	}
	.blog .tab-scroll{
		margin-bottom: -20px;
	}
	.blog .react-tabs .react-tabs__tab-list {
		margin-bottom: 0;
		width: calc(50% + 15px);
	}
	.blog .blog-posts .blog-featured-post .blog-featured-subtitle{
		margin-top: 60px;
		margin-bottom:20px;
	}
	.blog .blog-posts .blog-featured-post .blog-featured-title{
		font-size: 50px;
		line-height: 1;
	}
	.blog .add-home{
		height:200px;
		padding:0;
	}
	.blog .add-home .add-home-container{
		margin: 0;
		width: 100%;
		padding: 0;
		position: unset;
		height: 200px;
		background:none;
	}
	.blog .add-home .add-home-text{
		height:100%;
	}
	.blog .add-home .add-home-text .row{
		height:100%;
	}
	.blog .add-home .add-home-text .col-md-4{
		height:100%;
		display:flex;
		align-items:center;
		justify-content:center;
	}
	.blog .add-home .add-home-text .col-text{
		background:#fff;
	}
	.blog .add-home .add-home-text .col-text h3{
		color: #483A3A;
		font-size:30px;
		font-weight: bold;
	}
	.blog .add-home .add-home-text .col-text p{
		color: #483A3A;
		font-size:20px;
		margin:0;
	}
	.blog .col-triangle1{
		justify-content: end !important;
		overflow: hidden;
	}
	.blog .col-triangle2{
		justify-content: start !important;
		overflow: hidden;
	}
	.blog .triangle1{
		height:200%;
		width: 150px;
		transform: rotate(30deg) translateX(101px);
		background: #fff;
	}
	.blog .triangle2{
		height:200%;
		width: 150px;
		transform: rotate(30deg) translateX(-101px);
		background: #fff;
	}
	.blog .add-home .add-home-img{
		position:absolute;
	}
}