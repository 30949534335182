.house-single{
	background: #fff;
	padding-top:0;
}
.house-single .carousel-container{
	position: relative;
}
.house-single .carousel-indicators{
	margin-bottom: 5px;
}
.house-single .carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
.house-single .carousel.slide .carousel-item img{
	height: 300px;
	object-fit: cover;
}
.house-single label{
	color: #fff;
	font-size: 14px;
	font-weight: 400;
}
.house-single h4{
	font-size:18px;
	color:#483A3A;
	font-weight:bold;
	margin-bottom:30px;
}
.house-single hr{
	margin-top:30px;
	margin-bottom:30px;
}
.house-single .house-details{
	padding: 20px;
}
.house-single .house-rating svg{
	color: #0AA09F;
}
.house-single h1{
	color: #483A3A;
	font-weight:bold;
	font-size:30px;
}
.house-single .house-rating .house-price{
	color: #483A3A;
	font-size: 14px;
}
.house-single .house-location{
	margin-top:30px;
	color:#483A3A;
	font-size:14px;
}
.house-single .house-location p{
	margin-bottom:15px;
	display:flex;
	align-items:center;
}
.house-single .house-location a{
	color: #0AA09F;
	font-weight:bold;
}
.house-single .house-location .menu-icon{
	font-size:24px;
	margin-right: 20px;
}
.house-single .house-description{
	font-size:16px;
	color: #483A3A;
	margin-top:30px;
	line-height: 1.4;
}
.house-single .house-description a{
	color: #0AA09F;
	font-weight:bold;
}
.house-single .house-description-list ul{
	list-style:none;
	padding:0;
}
.house-single .house-description-list ul li{
	color:#B1A1A1;
	font-size:14px;
	clear:both;
}
.house-single .house-description-list ul li span{
	color:#483A3A;
	font-weight:bold;
	font-size:16px;
	float:right;
}
.house-single .house-description-list a{
	color:#0AA09F;
	font-weight:bold;
	font-size:16px;
}
.house-single .house-map-location p{
	color: #483A3A;
	font-size:14px;
	line-height: 1.2;
}
.house-single .house-map-location .map-container{
	height:150px;
	border-radius:5px;
}
.house-single .house-host .house-host-img{
	width:82px;
	height:82px;
	border-radius:50%;
	object-fit:cover;
	float: right;
}
.house-single .house-host h4{
	margin-bottom:0;
}
.house-single .house-host p{
	font-size:14px;
	color:#B1A1A1;
}
.house-single .house-host .house-host-rating svg{
	color:#0AA09F;
}
.house-single .house-host .house-host-rating span{
	color: #483A3A;
	font-size:14px;
}
.house-single .house-host .house-host-description{
	color: #483A3A;
	font-size:16px;
	line-height:1.4;
	margin-top:30px;
}
.house-single .house-host .house-host-description a{
	color: #0AA09F;
	font-weight:bold;
}
.house-single .house-host .btn-secondary{
	margin-top:30px;
}
.house-single .house-guidelines{
	position:relative;
}
.house-single .house-guidelines h4{
	margin-bottom:0;
}
.house-single .house-guidelines p{
	color: #B1A1A1;
	font-size:14px;
	margin-bottom:0;
}
.house-single .house-guidelines .menu-icon{
	position:absolute;
	right:0;
	top: 5px;
	color:#0AA09F;
	font-size:26px;
}
.house-single .house-comments h4{
	margin-bottom:10px;
}
.house-single .house-comments .house-comment-rating{
	color: #0AA09F;
	margin-bottom:30px;
}
.house-single .house-comments .house-comment-rating span{
	color: #483A3A;
	font-size:14px;
}
.house-single .house-comment{
	margin-bottom:50px;
	position:relative;
}
.house-single .house-comment:after{
	content:"";
	width:20px;
	height:5px;
	background-color:#483A3A;
	position:absolute;
	bottom:-30px;
}
.house-single .house-comment.last:after{
	display:none;
}
.house-single .house-comment.last{
	margin-bottom:30px;
}
.house-single .house-comment .house-comment-img-name{
	display:flex;
	align-items:center;
}
.house-single .house-comment .house-comment-img{
	width:50px;
	height:50px;
	border-radius:50%;
	object-fit: cover;
	margin-right:20px;
}
.house-single .house-comment .house-comment-name{
	color:#483A3A;
	font-weight:bold;
	font-size:14px;
	margin-bottom:0;
	line-height:1;
}
.house-single .house-comment .house-comment-date{
	color:#B1A1A1;
	font-size:10px;
	margin-bottom:0;
}
.house-single .house-comment .house-comment-img-name{
	margin-bottom:20px;
}
.house-single .house-comment .house-comment-message{
	font-size:16px;
	color:#483A3A;
}
.house-single .house-comment .house-comment-message a{
	color:#0AA09F;
	font-weight:bold;
}
.house-single .house-comments .read-all-comments,
.house-single .house-comments .report-house{
	color:#0AA09F;
	font-weight:bold;
	font-size:14px;
	display:flex;
	align-items:center;
}
.house-single .house-comments .read-all-comments .menu-icon,
.house-single .house-comments .report-house .menu-icon{
	color:#B1A1A1;
	font-size:20px;
	margin-left:10px;
}
.house-single .house-single-bottom,
.house-single .service-bottom{
	position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
	z-index:11;
}
.house-single .house-single-bottom .nav-bottom,
.house-single .service-bottom .nav-bottom{
	padding: 0 5px;
}
.house-single .house-single-bottom .nav-bottom .col,
.house-single .service-bottom .nav-bottom .col{
	display:flex;
	align-items: center;
}
.house-single .house-single-bottom .nav-bottom .col a,
.house-single .service-bottom .nav-bottom .col a{
	color: #0AA09F;
	font-weight:bold;
	font-size:14px;
}
.house-single .house-single-bottom .nav-bottom .col a,
.house-single .service-bottom .nav-bottom .col a{
	color: #0AA09F;
	font-weight:bold;
	font-size:14px;
}
.house-single .house-single-bottom .nav-bottom .col button,
.house-single .service-bottom .nav-bottom .col button{
	width:100%;
	font-weight:bold;
	font-size:14px;
}
.house-single .house-single-bottom .nav-bottom .hsb-label,
.house-single .service-bottom .nav-bottom .hsb-label{
	color: #0AA09F;
	font-size:14px;
	margin:0;
	position:unset;
	text-align: left;
}
.house-single .house-single-bottom .nav-bottom .hsb-label span,
.house-single .service-bottom .nav-bottom .hsb-label span{
	background: #0AA09F;
	color: #fff;
	border-radius:4px;
	font-size:12px;
	padding:2px 4px;
	margin-left: 5px;
}
.house-single .house-single-bottom .nav-bottom .hsb-price,
.house-single .service-bottom .nav-bottom .hsb-price{
	color: #483A3A;
	font-size:16px;
	font-weight:bold;
	margin:0;
	position:unset;
	text-align: left;
}
.house-single .btn-back{
	position:absolute;
	top:10px;
	left:0;
	border:0;
	background-color:transparent !important;
	z-index:99;
	padding:10px 20px !important;
}
.house-single .btn-back i{
	font-size:24px;
}
.house-single-booking,
.service-booking{
	position: fixed;
    bottom: 70px;
    left: 0;
    width: 100%;
	z-index:10;
	box-shadow: 0 3px 7px rgb(0 0 0 / 19%), 0 3px 31px rgb(0 0 0 / 23%)
}
.house-single-booking .hs-booking,
.service-booking .s-booking{
	background: #fff;
	padding: 20px;
	padding-top:0;
	border: 1px solid #ccc;
	border-bottom:0;
	border-top-left-radius:4px;
	border-top-right-radius:4px;
}
.house-single-booking .btn-close-house-booking,
.service-booking .btn-close-service-booking{
	border: 0;
    background: transparent;
    color: #B1A1A1;
    position: absolute;
    right: 15px;
    top: 10px;
	z-index: 999;
}

.service-booking .s-booking{
	padding:20px;
}
.house-single-booking .hs-booking .label{
	width:100%;
	color: #B1A1A1;
	font-size: 14px;
	margin-top: 30px;
	margin-bottom: 5px;
}
.service-booking .s-booking .label{
	width:100%;
	color: #B1A1A1;
	font-size: 14px;
	margin-bottom: 5px;
}
.service-booking-cancel-container{
	text-align: right;
}
.service-booking-cancel{
	margin-top: 30px;
	color: #0AA09F;
	font-weight:bold;
}
.service-booking .s-booking-calendar .react-datepicker{
	width: calc(100% + 30px);
    border: 0;
    margin: 0 -15px;
}
.service-booking .s-booking-calendar .react-datepicker__header{
	border:0;
	background-color: transparent;
}
.service-booking .s-booking-calendar .react-datepicker__current-month{
	line-height: 2;
	color: #483A3A;
	font-size:18px;
}
.service-booking .s-booking-calendar .react-datepicker__navigation{
	top:15px;
}
.service-booking .s-booking-calendar .react-datepicker__navigation:focus{
	outline:0;
}
.service-booking .s-booking-calendar .react-datepicker__navigation .react-datepicker__navigation-icon::before{
	border-color: #0AA09F;
	border-width: 2px 2px 0 0;
}
.service-booking .s-booking-calendar .react-datepicker__day-name,
.service-booking .s-booking-calendar .react-datepicker__day{
	width: calc(100vw / 7 - 19px);
    height: calc(100vw / 7 - 19px);
    margin: 8px;
	display: inline-flex;
    align-items: center;
    justify-content: center;
}
.service-booking .s-booking-calendar .react-datepicker__day-name{
	font-size:14px;
	color: #CBC0C0;
	font-weight:bold;
}
.service-booking .s-booking-calendar .react-datepicker__day-names .react-datepicker__day-name:nth-last-child(-n+2){
	color:#6EC3C2;
}
.service-booking .s-booking-calendar .react-datepicker__day{
	font-size:18px;
	color:#483A3A;
}
.service-booking .s-booking-calendar .react-datepicker__day--today{
	border: 1px solid #0AA09F;
	border-radius: 50%;
}
.service-booking .s-booking-calendar .react-datepicker__day--selected,
.service-booking .s-booking-calendar .react-datepicker__day--keyboard-selected{
	border-radius: 50%;
	background-color: #0AA09F;
	color:#fff;
}
.service-booking .s-booking-calendar .react-datepicker__day--outside-month{
	visibility:hidden;
}
.service-booking .s-booking-calendar .react-datepicker__month-container{
	width:100%;
}
.house-single-booking .hs-booking .bedroom-type-container{
	display:flex;
	align-items: center;
	padding: 4px 0;
	border: 2px solid transparent;
	border-radius: 4px;
}
.house-single-booking .hs-booking .bedroom-type-container.selected{
	border-color: #0AA09F;
}
.house-single-booking .hs-booking .bedroom-type-container .menu-icon{
	font-size: 30px;
	color: #0AA09F;
	padding: 5px;
}
.house-single-booking .hs-booking .bedroom-type-container .bedroom-type-title{
	color: #B1A1A1;
	font-size:12px;
	font-weight:bold;
	margin:0;
}
.house-single-booking .hs-booking .bedroom-type-container .bedroom-type-desc{
	font-size: 14px;
	color: #483A3A;
	margin:0;
}
.house-single-booking .hs-booking .react-datepicker-wrapper input{
	border: 2px solid #0AA09F;
	border-radius: 20px;
	padding: 5px 10px;
	color: #0AA09F;
	font-weight: bold;
	font-size:14px;
	width: 180px;
	height:40px;
}
.house-single-booking .hs-booking .datepicker-container{
	position:relative;
	width: fit-content;
}
.house-single-booking .hs-booking .datepicker-container .menu-icon{
	position:absolute;
	right: 13px;
	top: 7px;
	font-size: 25px;
	color: #B1A1A1;
}
.house-single-booking .hs-booking .btn-minus,
.house-single-booking .hs-booking .btn-plus{
	background: transparent !important;
	border: 2px solid #0AA09F !important;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	padding: 0px !important;
	color: #0AA09F !important;
	font-size: 40px !important;
	line-height:0;
	padding-bottom:1px !important;
}
.house-single-booking .hs-booking .btn-plus{
	padding-bottom:0 !important;
	padding-top: 1px !important;
}
.house-single-booking .hs-booking #numberPeople{
	border:0;
	border-bottom:1px solid #483A3A;
	color: #483A3A;
	width:40px;
	text-align: center;
	margin: 0 10px;
	font-size:16px;
}
.house-single-booking .hs-booking #numberPeople:focus{
	outline:0;
}
.house-single-booking .hs-booking .checkbox-container{
	color: #483A3A;
	font-size: 16px;
    height: 35px;
    display: flex;
    align-items: center;
}
.house-single-booking .hs-booking .checkbox-container .checkmark{
	border-radius:50%;
}
.house-single-booking .hs-booking .checkbox-container input:checked ~ .checkmark{
	background-color: transparent;
}
.house-single-booking .hs-booking .checkbox-container .checkmark::after{
	background-color: #0AA09F;
	border:0;
	transform: rotate(0);
	width:19px;
	height:19px;
	border-radius:50%;
	left:7px;
	top:7px;
}
.house-single-booking .hs-booking .checkbox-text-container .checkbox-container{
	margin:0;
}
.house-single-booking .hs-booking #partialAutonome{
	position: unset;
    opacity: 1;
    width: 22px;
    height: 27px;
    border: 0;
    border-bottom: 1px solid #483A3A;
    color: #483A3A;
    font-size: 16px;
    margin-top: 4px;
    text-align: center;
}
.house-single-booking .hs-booking #partialAutonome:focus{
	outline:0;
}
.house-single-booking .hs-booking .checkbox-text-container{
	display:flex;
}
.house-single-booking .hs-booking .terms{
	font-size:14px;
	color:#B1A1A1;
	margin:0;
	margin-top: 10px;
}
.house-single-booking .hs-booking .terms a{
	color: #0AA09F;
}